<template>
  <router-view />
</template>

<style lang="scss">
	@import "//at.alicdn.com/t/c/font_3659089_x24k9qk3ig.css";
	* {
		font-family: '华文细黑';
	}
	html {
		overflow-y: scroll;
	}
	body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input, p, blockquote, th, td { 
		margin:0; 
		padding:0; 
	}
	body { 
		font-size:16px; 
		color:#333; 
		background:#fff; 
		line-height:24px;
	}
	fieldset, img { 
		border:0; 
	}
	ol, ul, li { 
		list-style:none;
	}
	em { 
		font-style:normal; 
	}
	input, button, select, textarea { 
		outline:none; 
	}
	textarea { 
		resize:none; 
	}
	p{ 
		// text-align:justify; 
		text-justify:distribute;
	}
	a { 
		color: #333; 
		text-decoration:none; 
	}
	a:hover { 
		color: #333; 
		text-decoration:none; 
	}
	#app {
		height: 100%;
	}
</style>
