import { createStore } from 'vuex'

export default createStore({
  state: {
    baseOss: 'https://oss.shenshimuseum.com/',
    baseUrl: 'https://api.shenshimuseum.com/api/v1/',
    // baseUrl: 'http://localhost:8082/api/v1/',
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
