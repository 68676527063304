import { createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/login',
		name: 'login',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
	},
	{
		path: '/',
		name: 'home',
		component: HomeView,
		redirect: "/product",
		children: [
			{
				path: "/product",
				name: "product",
				meta: {
					isShow: true,
					title: "作品列表",
					icon: "zuopin"
				},
				component: () => import(/* webpackChunkName: "product" */ '../views/ProductView.vue')
			},
			{
				path: "/banner",
				name: "banner",
				meta: {
					isShow: true,
					title: "封面列表",
					icon: "jiutuhaibao"

				},
				component: () => import(/* webpackChunkName: "banner" */ '../views/BannerView.vue')
			},
			{
				path: "/show",
				name: "show",
				meta: {
					isShow: true,
					title: "项目列表",
					icon: "zhanlan"

				},
				component: () => import(/* webpackChunkName: "show" */ '../views/ShowView.vue')
			},
			{
				path: "/artist",
				name: "artist",
				meta: {
					isShow: true,
					title: "作家列表",
					icon: "yishujia"

				},
				component: () => import(/* webpackChunkName: "show" */ '../views/ArtistView.vue')
			},
			{
				path: "/brochure",
				name: "brochure",
				meta: {
					isShow: true,
					title: "宣传册",
					icon: "xiaocezi"

				},
				component: () => import(/* webpackChunkName: "show" */ '../views/BrochureView.vue')
			}
		]
	}
	
]

const router = createRouter({
	// history: createWebHistory(process.env.BASE_URL),
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})
router.beforeEach((to, form, next) => {
	const user: any | null = localStorage.getItem('user')
	if (!user && to.path !== '/login') {
		next('/login')
	} else {
		next()
	}
})
export default router
