
import { ElMessage, ElMessageBox } from "element-plus";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
	name: "HomeView",
	setup() {
		let user:any = localStorage.getItem('user')
		user = JSON.parse(user)
		const router = useRouter()
		const routerList = router.getRoutes().filter(v => v.meta.isShow)
		const onLogout = ()=>{
			ElMessageBox.confirm(
                '此操作将退出登录, 是否继续?',
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
				var user:any = {
					openid: '',
					token: ''
				}
				localStorage.setItem("user", JSON.stringify(user))
				router.push('/login')

            }).catch(() => {
                ElMessage({
                    type: 'info',
                    message: '已取消',
                })
            })
		}
		return { user, routerList, onLogout }
	},
	components: {}
});
